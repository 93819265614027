section.content-section.advanced-map {
	padding-top: 0;
	padding-bottom: 0;

	figure#mapbox {
		height: 90vh;

		.gm-style-iw {
			padding: 20px 20px 15px !important;
			max-width: 300px;

			&,
			* {
				font-family: $primary-font;
			}

			small {
				text-transform: uppercase;
				font-size: 0.55em;
				vertical-align: middle;
				color: $white;
				border-radius: 3px;
				font-family: $primary-font;
				line-height: 1;
				display: inline-block;
				backround-color: $slate;
				margin-left: 4px;
				@include set_amiko_padding(5px, 5px);
			}

			h4 {
				margin: 0 0 4px;
				font-size: 1.2rem;
				font-family: $secondary-font;
			}

			p {
				margin: 5px 0 0;
				font-size: 0.9rem;

				&.impact {
					font-weight: 600;
				}

				&.summary {
					font-size: 0.9rem;
				}
			}
		}
	}

	header {
		text-align: center;
		position: relative;

		h4.notice-box {
			display: inline;
			position: absolute;
			top: 15px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			background-color: $white;
			border-radius: 100px;
			z-index: 10000;
			font-size: 1rem;
			text-align: center;
			opacity: 0;
			transition: opacity 0.4s ease;
			border: 1px solid darken($white, 10);
			box-shadow: 0 0 5px rgba($black, 0.3);
			@include set_amiko_padding(10px, 15px);

			&.active {
				opacity: 1;
			}
		}
	}

	aside.road-details {
		background-color: darken($slate, 10);
		color: $white;
		width: 0;
		overflow: scroll;
		height: 100%;
		transition: width 0.4s ease;

		a {
			color: $orange;

			&:hover {
				text-decoration: underline;
			}
		}

		figure.loading {
			text-align: center;

			i {
			}
		}

		.results {
			h2 {
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				&.alert-types {
					li.alert-type {
						margin-top: $section-padding / 4;

						h4 {
							font-size: 1.1rem;
						}

						h5 {
							font-size: 0.9rem;
							opacity: 0.6;
						}
					}
				}

				&.alerts {
					li.road-alert {
					}
				}
			}
		}

		&.open {
			width: 25vw;
			padding: 3vw;
		}
	}
}
