/*** Fonts ***/
$amiko: "Amiko", sans-serif;
$nanum: "Nanum Gothic", sans-serif;
$primary-font: $amiko;
$secondary-font: $nanum;

/*** Colors ***/
$white: #fff;
$black: #000;
$orange: #fd963a;
$green: #62c76a;
$blue: #428eea;
$slate: #6f8094;
$grey: #fafafa;
$background-color: $grey;
$primary-color: $black;
$secondary-color: lighten($black, 10);
$text-color: #3b3b3b;
$gceo_colors: (white, $white), (green, $green), (blue, $blue), (orange, $orange), (grey, $grey);

$brand-colors: (
	"white": (
		"color": $blue,
		"bg": $white,
		"header-color": $text-color,
		"text-color": $slate,
		"hover-bg": $white,
		"hover-color": $blue
	),
	"black": (
		"color": $white,
		"bg": $black,
		"header-color": $white,
		"text-color": $white,
		"hover-bg": $black,
		"hover-color": $white
	),
	"blue": (
		"color": $white,
		"bg": $blue,
		"header-color": $white,
		"text-color": $white,
		"hover-bg": darken($blue, 10),
		"hover-color": $white
	),
	"orange": (
		"color": $white,
		"bg": $orange,
		"header-color": $white,
		"text-color": $white,
		"hover-bg": darken($orange, 10),
		"hover-color": $white
	),
	"green": (
		"color": $white,
		"bg": $green,
		"header-color": $white,
		"text-color": $white,
		"hover-bg": darken($green, 10),
		"hover-color": $white
	),
	"grey": (
		"color": $text-color,
		"bg": $grey,
		"header-color": $text-color,
		"text-color": $text-color,
		"hover-bg": darken($grey, 10),
		"hover-color": $text-color
	)
);

/*** Template Vars ***/
$section-padding: 100px;
$section-padding-md: $section-padding / 2;
$card-box-shadow: 0 0 20px rgba($black, 0.2);
$card-padding: 40px;
$card-border-radius: 10px;

/*** Resetting Bootstrap Breakpoints ***/
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);
