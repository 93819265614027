body {
	.gform_wrapper {
		width: 800px;
		max-width: 100%;
		margin: 0 auto;

		.gform_body {
			.gform_fields {
				.gfield {
					select,
					textarea,
					input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
						$field-height: 3rem;
						line-height: $field-height;
						height: $field-height;
						padding: 0 ($field-height / 2.5);
						width: 100%;
						-webkit-appearance: none;
						appearance: none;
						border: 1px solid rgba($text-color, 0.25);
						border-radius: 0.25rem;
						margin: 0;

						&:focus {
							border-color: $text-color;
						}
					}

					.gfield_label {
						margin: 1rem 0 0;
					}

					.ginput_container {
						margin: 0;
					}
				}
			}
		}

		.gform_footer,
		.gform_page_footer {
			display: flex;

			.button {
				@extend .btn;
				@extend .btn.slate;
				@extend .btn.narrow;

				&.gform_next_button {
					margin: 0 0 0 auto;
				}
			}
		}
	}
}
