@import url("https://fonts.googleapis.com/css?family=Amiko:400,600,700|Nanum+Gothic:400,700");

@font-face {
	font-family: "gceo";
	src: url("assets/dist/fonts/gceo.eot?lg27rk");
	src: url("assets/dist/fonts/gceo.eot?lg27rk#iefix") format("embedded-opentype"), url("assets/dist/fonts/gceo.ttf?lg27rk") format("truetype"), url("assets/dist/fonts/gceo.woff?lg27rk") format("woff"), url("assets/dist/fonts/gceo.svg?lg27rk#gceo") format("svg");
	font-weight: normal;
	font-style: normal;
}

i[class*="gceo-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "gceo" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 0;
	display: inline-block;
	font-size: inherit;
	text-rendering: auto;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.gceo-icon-twitter-alt:before {
	content: "\e911";
}
.gceo-icon-arrow:before {
	content: "\e900";
}
.gceo-icon-email:before {
	content: "\e901";
}
.gceo-icon-facebook:before {
	content: "\e902";
}
.gceo-icon-instagram:before {
	content: "\e903";
}
.gceo-icon-key:before {
	content: "\e904";
}
.gceo-icon-magnifier:before {
	content: "\e905";
}
.gceo-icon-phone:before {
	content: "\e906";
}
.gceo-icon-speech-bubble:before {
	content: "\e907";
}
.gceo-icon-twitter:before {
	content: "\e908";
}
.gceo-icon-youtube:before {
	content: "\e909";
}
.gceo-icon-construction-worker:before {
	content: "\e90a";
}
.gceo-icon-garage:before {
	content: "\e90b";
}
.gceo-icon-house:before {
	content: "\e90c";
}
.gceo-icon-office:before {
	content: "\e90d";
}
.gceo-icon-questions:before {
	content: "\e90e";
}
.gceo-icon-square-tool:before {
	content: "\e90f";
}
.gceo-icon-traffic-cone:before {
	content: "\e910";
}
