section.content-section.alert-details {
    section.alert-widget {
        &.documents {
            ul {
                $spacing: 8px;
                margin: 0 -($spacing);
                padding: 0;
                list-style: none;

                li {
                    float: left;
                    width: 33.333%;
                    padding: 0 $spacing;
                    margin-top: ($spacing * 2);

                    a {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                    }

                    figure {
                        padding: $spacing * 2;
                        background-color: $white;
                        border-radius: $card-border-radius;
                        text-align: center;
                        transition: box-shadow 0.4s ease;
                        position: relative;
                        border: 1px solid darken($white,15);
                        border-radius: $card-border-radius;

                        img {
                            margin: 0 0 $spacing;
                        }

                        h4 {
                            font-size: 1rem;
                            margin: 0;
                        }

                        span {
                            &.filesize {
                                display: block;
                                font-size: 0.9em;
                                opacity: 0.5;
                            }
                        }
                    }

                    &:hover {
                        figure {
                            box-shadow: 0 0 15px rgba($black,0.15);
                        }
                    }
                }
            }
        }
    }
}