.loop-items {
	.loop-list {
		.loop-item {
			@extend %card;
			background-color: $grey;

			hgroup {
				h2 {
					margin: 0.1em 0 0;
					line-height: 1;
					font-family: $nanum;
				}
			}

			article {
				p {
					margin: 1em 0;
				}
			}

			+ .loop-item {
				margin-top: $section-padding / 3;
			}
		}
	}

	&.columns {
		.loop-list {
			column-count: 3;

			.loop-item {
				display: inline-block;
			}
		}
	}
}
