section.hero {
	hgroup.hero-title {
		text-align: center;
		color: $white;
		margin: 4vh 0;

		.title {
			@include convert_font_size(93);
			font-family: $nanum;
			font-weight: 300;
			margin-bottom: 0;
		}

		.subtitle {
			@include convert_font_size(32);
			text-transform: uppercase;
			font-weight: 300;
			margin: 0.5em 0 0;
		}
	}

	&.page {
		hgroup.hero-title {
			margin-bottom: 12vh;

			@include media-breakpoint-down(md) {
				margin: 10vh 0;
			}
		}
	}

	&.form {
		$tile-info: $slate;
		$tile-twitter: #1da1f2;
		$tile-construction: $orange;
		$tile-padding: 35px;
		$top-bar-height: 5px;
		$top-bar-offset: 20px;
		$btn-height: 45px;
		position: relative;

		h1 {
			text-align: center;
			font-weight: 300;
			color: $white;
			font-family: $nanum;
			//font-size: 2.9rem;
			@include convert_font_size(93);
			margin-bottom: 0;
		}

		form {
			width: 50%;
			min-width: 525px;
			max-width: 100%;
			margin: 0 auto 8vh;
			background-color: $white;
			border-radius: $card-border-radius;
			padding: 5px;
			white-space: nowrap;

			@include media-breakpoint-down(md) {
				margin-bottom: 4vh;
			}

			@include media-breakpoint-down(md) {
				width: auto;
				min-width: 0;
			}

			label {
				margin-left: 7%;
				margin-right: 10px;

				@include media-breakpoint-down(md) {
					margin-left: 0;
				}

				@include media-breakpoint-down(sm) {
					display: none;
				}

				i.gceo-icon-magnifier {
					font-size: 1.15rem;
					line-height: 1;
					position: relative;
					top: -1px;
				}
			}

			input {
				&[type="text"] {
					border: none;
					height: 100%;
					min-width: 270px;
					max-width: 100%;
					@include convert_font_size(32);
					@include set_amiko_padding(10px, 0);

					@include placeholder {
						//font-weight: 700;
						text-transform: uppercase;
					}

					@include media-breakpoint-down(sm) {
						@include set_amiko_padding(20px, 0);
						@include convert_font_size(28);
						text-align: center;
					}
				}
			}

			button {
				height: 100%;
			}
		}

		section.alert-tiles {
			position: relative;
			z-index: 5;

			a.slick-arrow {
				$arrow-height: 80px;
				$arrow-offset: 5vw;
				font-size: $arrow-height;
				line-height: 0;
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -($arrow-height / 2);
				color: $white;
				opacity: 0.8;
				transition: all 0.4s ease;
				cursor: pointer;

				&.slick-disabled {
					opacity: 0;
				}

				&.prev {
					left: -($arrow-offset);
				}

				&.next {
					right: -($arrow-offset);
				}
			}

			article.tile {
				position: relative;
				background-color: $white;
				height: 100%;
				border-radius: $card-border-radius;
				text-align: center;
				box-shadow: $card-box-shadow;
				z-index: 1;
				padding: $tile-padding;

				hgroup {
					padding-top: 1em;

					figure {
						margin-bottom: 1em;

						i {
							color: $tile-info;
							line-height: 1;
							@include convert_font_size(80);
						}

						img {
							border-radius: 100%;
							width: 45%;
							border: 4px solid $tile-info;
							margin: 0 auto;
							max-width: 200px;

							@include media-breakpoint-down(md) {
								max-width: 150px;
							}
						}
					}

					h3,
					h4 {
						font-weight: 300;
					}

					h3 {
						@include convert_font_size(54);
						font-family: $nanum;
						margin-bottom: 0.5em;
					}

					h4 {
						@include convert_font_size(27);
						text-transform: uppercase;
						margin: 0;
					}
				}

				footer {
					position: absolute;
					bottom: $tile-padding;
					left: $tile-padding;
					right: $tile-padding;
					z-index: 2;

					a {
						color: $tile-info;

						&.btn {
							border-color: $tile-info;
						}
					}
				}

				.message {
					@include convert_font_size(30);
					line-height: 1.7em;
					margin: 2em 5%;

					.weather {
						text-transform: uppercase;
						padding: 1.5em 0;

						span {
							display: block;
							line-height: 1;

							&.temp {
								@include convert_font_size(87);
								font-family: $nanum;
							}

							&.condition {
								@include convert_font_size(27);
							}
						}
					}
				}

				&.quick-link {
					@include convert_font_size(35);
					font-family: $nanum;
					display: block;
					color: $text-color;
					padding: $tile-padding ($tile-padding / 2);
					transition: all 0.4s ease;

					i,
					svg {
						@include convert_font_size(90);
						display: block;
						color: $green;
						line-height: 1;
						margin: 0.5em auto;

						path {
							fill: $green;
						}
					}

					svg {
						height: 45px;
					}

					a {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}

					&:before {
						background-color: $green;
					}

					&:hover {
						transform: scale(1.05);
						box-shadow: 0 0 10px rgba($black, 0.4);
					}
				}

				&.enhanced {
					padding-top: $top-bar-offset + $top-bar-height;
					padding-bottom: $tile-padding + $btn-height;
				}

				&.type-closure,
				&.type-twitter,
				&.type-road-condition {
					padding-top: 2.75em;
				}

				&.type-closure {
					hgroup {
						figure {
							i {
								color: $tile-construction;
							}

							img {
								border-color: $tile-construction;
							}
						}
					}

					footer {
						a {
							color: $tile-construction;

							&.btn {
								border-color: $tile-construction;
							}
						}
					}

					&:before {
						background-color: $tile-construction;
					}
				}

				&.type-twitter {
					hgroup {
						figure {
							i {
								color: $tile-twitter;
							}

							img {
								border-color: $tile-twitter;
							}
						}
					}

					footer {
						a {
							color: $tile-twitter;

							&.btn {
								border-color: $tile-twitter;
							}
						}
					}

					&:before {
						background-color: $tile-twitter;
					}
				}

				&:before {
					content: "";
					height: $top-bar-height;
					position: absolute;
					top: $top-bar-offset;
					left: 0;
					right: 0;
					background-color: $tile-info;
				}
			}
		}
	}

	&.home {
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 30vh;
			z-index: 0;
			@include linear_gradient(rgba($background-color, 0), $background-color);
		}
	}
}
