section.content-section {
	padding: $section-padding 0;

	@include media-breakpoint-down(md) {
		padding: $section-padding-md 0;
	}

	.section-title {
		hgroup {
			margin-bottom: 1.5em;

			.title {
				@include convert_font_size(93);
				font-family: $nanum;
				font-weight: 300;
				line-height: 1.1em;

				@include media-breakpoint-down(md) {
					@include convert_font_size(73);
				}
			}

			.subtitle {
				@include convert_font_size(32);
				text-transform: uppercase;
				font-weight: 300;
				line-height: 1.6em;
				margin: 1em 0 0;

				@include media-breakpoint-down(md) {
					@include convert_font_size(28);
				}
			}
		}
	}

	.card {
		@extend %card;
	}

	.container {
		position: relative;
		z-index: 2;
	}

	+ section.content-section {
		border-top: 1px solid darken($white, 8);
	}

	&[class*="section-bg"] {
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
		}

		@each $label, $map in $brand-colors {
			&.section-bg-#{$label} {
				background-color: map-get($map, bg);

				.container,
				.sidebar {
					z-index: 2;
				}

				.container {
					position: relative;
				}

				&::after {
					background-color: rgba(map-get($map, bg), 0.85);
				}
			}
		}
	}
}

@import "centered-content";
@import "split-content";
@import "advanced-map";
@import "alert-details";
