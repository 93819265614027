/*** Header ***/

//$logo-width: 250px;
//$logo-width-md: 200px;
//$logo-glow-size: $logo-width * 0.18;

header.main {
	//position: absolute;
	width: 100%;
	line-height: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;

	nav {
		ul {
			&.menu {
				margin: 0;
				padding: 0;
				list-style: none;

				> li.menu-item {
					a {
						display: block;
					}
				}
			}

			&.sub-menu {
				> li.menu-item {
					a {
						span.open-menu {
							float: right;
							position: relative;
							padding-left: 15px;
							width: 25px;

							&:before {
								content: "";
								position: absolute;
								top: -0.25rem;
								bottom: -0.25rem;
								left: 0;
								width: 1px;
								background-color: $white;
								opacity: 0.3;
							}
						}
					}
				}

				&.open {
					display: block !important;
				}
			}
		}
	}

	aside.top-bar {
		background-color: $white;

		@include media-breakpoint-down(md) {
			display: none;
		}

		nav {
			text-transform: uppercase;
			font-weight: 700;

			ul.menu {
				> li {
					float: left;

					> a {
						@include convert_font_size(27);
						display: block;
						position: relative;

						i {
							font-size: 1.2em;
							position: relative;
							top: -1px;
							margin-right: 3px;
						}

						@include media-breakpoint-down(lg) {
							@include convert_font_size(24);
						}
					}

					&.menu-item {
						padding: 0 25px;

						@include media-breakpoint-down(lg) {
							padding: 0 15px;
						}

						> a {
							@include set_amiko_padding(10px, 0);
							color: $orange;

							&:after {
								content: "";
								height: 2px;
								background-color: $orange;
								position: absolute;
								left: 50%;
								right: 50%;
								bottom: 10px;
								transition: all 0.5s ease;
							}
						}

						> ul.sub-menu {
							position: absolute;
							background-color: $white;
							list-style: none;
							margin: 0 0 0 -15px;
							padding: 5px 0;
							display: none;
							z-index: 1000;
							border-radius: 0 0 5px 5px;

							> li {
								> a {
									@include set_amiko_padding(3px, 15px);
									@include convert_font_size(27);
									display: block;
									color: $text-color;
								}

								> ul.sub-menu {
									display: none;
									list-style: none;
									padding: 10px 0;
									margin: 0;
									background-color: darken($white, 5);

									> li {
										> a {
											@include set_amiko_padding(3px, 15px);
											@include convert_font_size(25);
										}
									}
								}
							}
						}

						&:hover {
							> a {
								&:after {
									left: 0;
									right: 0;
								}
							}

							> ul.sub-menu {
								display: block;
							}
						}
					}

					&.search {
						> a {
							padding: 15px 10px;
						}
					}

					&.request-assistance,
					&.email-us,
					&.search {
						> a {
							color: $text-color;

							&:after {
								background-color: $text-color;
							}
						}
					}
				}
			}
		}
	}

	section.logo-bar {
		@include media-breakpoint-down(md) {
			background-color: $white;
			padding: 10px 0;
		}

		nav {
			&.main-menu {
				$link-x-spacing: 25px;
				$link-x-spacing-md: 20px;
				margin-right: (-$link-x-spacing);

				@include media-breakpoint-down(lg) {
					margin-right: (-$link-x-spacing-md);
				}

				@include media-breakpoint-down(md) {
					padding: 0;
					margin: 0;
					display: none;
				}

				> .menu {
					display: flex;
				}

				.menu {
					> .menu-item {
						text-transform: uppercase;
						font-weight: 700;
						margin: 30px $link-x-spacing;
						padding: 10px 0;
						position: relative;

						@include media-breakpoint-down(lg) {
							margin: 20px $link-x-spacing-md;
						}

						a {
							.open-menu {
								position: relative;
								padding-left: 0.75rem;
								margin-left: 1rem;
								line-height: 1;

								&:before {
									background-color: rgba($black, 0.5);
								}
							}
						}

						> a {
							color: $white;
							position: relative;
							display: flex;
							align-items: center;
							white-space: nowrap;
							@include convert_font_size(28);
							@include set_amiko_padding(10px, 0);

							@include media-breakpoint-down(lg) {
								@include convert_font_size(26);
								@include set_amiko_padding(10px, 0);
							}

							i,
							svg {
								font-size: 1.4em;
								margin-right: 0.5rem;
								height: 20px;
								width: 20px;

								path {
									fill: $white;
								}
							}

							&:after {
								content: "";
								height: 2px;
								background-color: $white;
								position: absolute;
								left: 50%;
								right: 50%;
								bottom: 5px;
								transition: all 0.5s ease;
							}
						}

						> .sub-menu {
							position: absolute;
							top: 80%;
							left: 50%;
							transform: translateX(-50%);
							min-width: 175%;
							background-color: $white;
							list-style: none;
							padding: 10px 0;
							z-index: 1000;
							opacity: 0;
							visibility: hidden;
							transition: all 0.4s ease;
							@include pointer_arrow("top", 8px, $white, 5px);

							> li {
								line-height: 1.2em;

								> a {
									display: flex;
									white-space: nowrap;
									@include set_amiko_padding(5px, 15px);
								}

								.sub-menu {
									display: none;
									list-style: none;
									margin: 5px 0;
									padding: 0;
									background-color: darken($white, 5);
									padding: 10px 0;

									> li.menu-item {
										> a {
											@include convert_font_size(25);
											@include set_amiko_padding(5px, 15px);
											line-height: 1.4em;
											color: $text-color;
										}
									}
								}
							}
						}

						&:hover {
							> a {
								&:after {
									left: 0;
									right: 0;
								}
							}

							> ul.sub-menu {
								opacity: 1;
								visibility: visible;
								top: 100%;
							}
						}
					}
				}
			}

			&.mobile-menu {
				display: none;
				position: relative;

				@include media-breakpoint-down(md) {
					display: block;
				}

				a.mobile-toggle-button {
					padding: 12px 15px;
					background-color: $text-color;
					color: $white;
					border-radius: 5px;
					line-height: 1;
					text-transform: uppercase;
					display: block;

					i {
						margin-right: 4px;
					}

					&.on {
						border-radius: 5px 5px 0 0;
						background-color: darken($text-color, 5);
					}
				}

				ul.menu {
					position: absolute;
					background-color: darken($text-color, 5);
					right: 0;
					top: 100%;
					padding: 10px 0;
					margin: 0;
					list-style: none;
					z-index: 1000;
					border-radius: 5px 0 5px 5px;
					display: none;

					li {
						a {
							color: $white;
							display: block;
						}
					}

					> li {
						float: none;

						> a {
							@include convert_font_size(32);
							@include set_amiko_padding(8px, 20px);
							text-transform: uppercase;
							white-space: nowrap;

							i:not(.fa-angle-down):not(.fa-angle-left) {
								font-size: 1.4em;
								position: relative;
								top: -2px;
								margin-right: 3px;
							}
						}

						ul.sub-menu {
							display: none;
							list-style: none;
							margin: 0;
							padding: 10px 0;
							background-color: rgba($black, 0.25);

							> li {
								> a {
									@include set_amiko_padding(5px, 20px);
									white-space: nowrap;
								}
							}
						}

						&#menu-item-277 {
							position: relative;
							padding-top: 8px;
							margin-top: 8px;

							&:before {
								content: "";
								position: absolute;
								top: 0px;
								left: 15px;
								right: 15px;
								height: 1px;
								background-color: $white;
								opacity: 0.3;
							}
						}
					}
				}
			}
		}

		.logo {
			@include set_logo_size_and_position(215px);

			@include media-breakpoint-down(lg) {
				@include set_logo_size_and_position(200px);
			}

			@include media-breakpoint-down(md) {
				width: auto;
			}

			a {
				display: block;
				width: 100%;
				position: absolute;

				@include media-breakpoint-down(md) {
					top: 0;
					left: 0;
					margin: 0;
					width: auto;
					position: relative;
					white-space: nowrap;
				}

				img {
					display: block;
					width: 100%;

					@include media-breakpoint-down(md) {
						display: inline;
						width: 75px;
						margin: 0;
					}

					@include media-breakpoint-down(sm) {
						width: 65px;
					}

					&.desktop {
						@include media-breakpoint-down(md) {
							display: none;
						}
					}

					&.mobile {
						display: none;

						@include media-breakpoint-down(md) {
							display: inline-block;
						}
					}
				}

				span {
					@include convert_font_size(23);
					color: $white;
					text-align: center;
					display: block;
					line-height: 1.5em;
					font-weight: 600;
					text-transform: uppercase;
					white-space: normal;

					@include media-breakpoint-down(md) {
						@include convert_font_size(34);
						display: inline-block;
						padding: 0;
						margin: 0;
						max-width: 200px;
						text-align: left;
						padding-left: 15px;
						vertical-align: middle;
						line-height: 1.3em;
						color: $text-color;
					}

					@include media-breakpoint-down(sm) {
						@include convert_font_size(28);
						width: calc(100% - 65px);
						padding-left: 10px;
					}
				}
			}
		}
	}
}
