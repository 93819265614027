.btn {
	@include set_amiko_padding(18px, 75px);
	@include convert_font_size(27);
	display: inline-block;
	line-height: 1;
	background: none;
	border: none;
	text-transform: uppercase;
	border-radius: 5px;
	font-weight: 700;
	border: 2px solid rgba($white, 0);
	max-width: 100%;
	cursor: pointer;
	white-space: normal;

	@include media-breakpoint-down(md) {
		@include set_amiko_padding(18px, 35px);
	}

	+ .btn {
		margin-top: 0.5em;
	}

	i.gceo-icon-arrow {
		font-size: 0.95em;
		position: relative;
		top: -1px;
		margin-left: 5px;
	}

	&.orange {
		background-color: $orange;
		color: $white !important;
	}

	&.green {
		background-color: $green;
		color: $white !important;
	}

	&.slate {
		background-color: $slate;
		color: $white !important;
	}

	&.transparent {
		background-color: transparent;

		&.orange {
			border-color: $orange;
			color: $orange !important;
		}

		&.slate {
			border-color: $slate;
			color: $slate !important;
		}
	}

	&.medium {
		@include set_amiko_padding(15px, 60px);
		@include convert_font_size(24);
	}

	&.small {
		@include set_amiko_padding(12px, 30px);
		@include convert_font_size(22);
	}

	&.very-small {
		@include set_amiko_padding(8px, 10px);
		@include convert_font_size(22);
	}

	&.narrow,
	&.full-width {
		padding-left: 25px;
		padding-right: 25px;
	}

	&.full-width {
		width: 100%;
	}

	&.equal {
		padding-left: 10px;
		padding-right: 10px;
		min-width: 15rem;
	}
}
