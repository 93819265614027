/*** Footer ***/

footer.main {
	@include convert_font_size(27);
	background-color: $white;
	padding-top: $section-padding / 2;
	text-transform: uppercase;

	a {
		color: $text-color;
		font-weight: 700;

		&.logo {
			display: block;
			max-width: 100px;
			width: 100%;

			img {
				display: block;
			}
		}
	}

	.address-content {
		@include convert_font_size(27);
		line-height: 1.5em;
		margin: 0;

		i {
			@include convert_font_size(34);
		}

		p {
			margin: 0;

			+ p {
				margin-top: 0.5em;
			}
		}
	}

	nav {
		text-align: right;
		margin-bottom: 1.25em;

		@include media-breakpoint-down(lg) {
			text-align: left;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
			}
		}

		&.main-menu {
			$spacing: 15px;
			margin-right: -($spacing);

			@include media-breakpoint-down(lg) {
				margin-left: -($spacing);
				margin-right: 0;
			}

			ul {
				li {
					a {
						padding: 5px $spacing;
					}
				}
			}
		}

		&.social-links {
			$spacing: 2px;
			margin-right: -($spacing);

			ul {
				li {
					a {
						padding: 0 $spacing;

						i {
							@include convert_font_size(50);
						}
					}
				}
			}
		}
	}

	a.employee-login {
	}

	.copyright {
		@include convert_font_size(20);
		@include set_amiko_padding(15px, 0);
		text-align: center;
		margin-top: $section-padding / 2;
		border-top: 1px solid darken($white, 10);
	}
}
