%card {
	background-color: $white;
	box-shadow: $card-box-shadow;
	padding: $card-padding;
	border-radius: $card-border-radius;

	header.section-title {
		article {
			padding: 0 20%;

			p {
				margin-bottom: 2.5em;
			}
		}
	}
}
