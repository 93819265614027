section.content-section.centered-content {
	text-align: center;

	header.section-title {
		hgroup {
			padding: 0 15%;

			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}

		article {
			padding: 0 5%;

			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}
	}
}
