/*** Global App Styles ***/

body {
	font-family: $primary-font;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	}

	p,
	ol,
	ul,
	blockquote {
		@include convert_font_size(30);
		line-height: 1.7em;
	}

	.badge {
		color: $white;
	}
}
